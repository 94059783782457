.PageError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.PageLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.nombretarifa {
  margin-left: 15px;
}

/*loader*/
.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.roaming-herramientas {
  margin-top: 25px;
}

.switch-button-roaming {
  display: inline-block;
}

.switch-button-roaming .switch-button__checkbox-roaming {
  display: none;
}

.switch-button-roaming .switch-button__label-roaming {
  width: 3.5rem;
  height: 1.8rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  border-color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left: 15px;
  background: greenyellow;
}

.switch-button-roaming .switch-button__label-roaming:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);

}

.switch-button-roaming .switch-button__checkbox-roaming:checked+.switch-button__label-roaming {
  background-color: red;

}

.switch-button-roaming .switch-button__checkbox-roaming:checked+.switch-button__label-roaming:before {
  transform: translateX(2rem);
}

.switch-button-roaming-apa {
  display: inline-block;
}

.switch-button-roaming-apa .switch-button__checkbox-roaming-apa {
  display: none;
}

.switch-button-roaming-apa .switch-button__label-roaming-apa {
  width: 3.5rem;
  height: 1.8rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  border-color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left: 15px;
  background: red
}

.switch-button-roaming-apa .switch-button__label-roaming-apa:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);

}

.switch-button-roaming-apa .switch-button__checkbox-roaming-apa:checked+.switch-button__label-roaming-apa {
  background-color: greenyellow;

}

.switch-button-roaming-apa .switch-button__checkbox-roaming-apa:checked+.switch-button__label-roaming-apa:before {
  transform: translateX(2rem);
}

.switch-button-roaming1 {
  display: inline-block;
}

.switch-button-roaming1 .switch-button__checkbox-roaming1 {
  display: none;
}

.switch-button-roaming1 .switch-button__label-roaming1 {
  width: 3.5rem;
  height: 1.8rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  border-color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left: 15px;
  background: greenyellow;
}

.switch-button-roaming1 .switch-button__label-roaming1:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);

}

.switch-button-roaming1 .switch-button__checkbox-roaming1:checked+.switch-button__label-roaming1 {
  background-color: red;

}

.switch-button-roaming1 .switch-button__checkbox-roaming1:checked+.switch-button__label-roaming1:before {
  transform: translateX(2rem);
}


.switch-button-roaming-apa1 {
  display: inline-block;
}

.switch-button-roaming-apa1 .switch-button__checkbox-roaming-apa1 {
  display: none;
}

.switch-button-roaming-apa1 .switch-button__label-roaming-apa1 {
  width: 3.5rem;
  height: 1.8rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  border-color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left: 15px;
  background: red
}

.switch-button-roaming-apa1 .switch-button__label-roaming-apa1:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);

}

.switch-button-roaming-apa1 .switch-button__checkbox-roaming-apa1:checked+.switch-button__label-roaming-apa1 {
  background-color: greenyellow;

}

.switch-button-roaming-apa1 .switch-button__checkbox-roaming-apa1:checked+.switch-button__label-roaming-apa1:before {
  transform: translateX(2rem);
}


.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.num-factura-ticket {
  margin-top: 5px;
  text-align: left;
  margin-right: 5px;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

.loader-text {
  color: var(--color-corporativo);
}

.nombre-contacto {
  margin-left: 7px;
  color: var(--color-corporativo);
  font-weight: 600;

}

.icono-agenda {
  color: var(--color-corporativo);
}

.div-contacto-nombre {
  margin-top: 5px;
  text-align: left;
  margin-right: 5px;
  margin-left: 10px;
}

.div-numero-contacto {
  text-align: left;
  margin-right: 5px;
  margin-left: 34px;
}

.div-email-contacto {
  text-align: right;
  margin-right: 15px;
  padding-bottom: 15px;

}

.agendas-tabs .tab-content {
  background: white !important;
}

.numero-contacto {
  color: var(--color-corporativo);
  font-weight: 600;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

/* Navbar superior*/

.Navbar-Logotipo {
  text-decoration: none;
  text-align: left;
  color: black;
  letter-spacing: 0.5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 0px;
}

.Navbar-Logotipo-ios {
  text-decoration: none;
  text-align: left;
  color: black;
  letter-spacing: 0.5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 25px;
}

.ion-botton-nav {
  --padding-top: 0px;
}

.ion-botton-nav-ios {
  --padding-top: 15px;
}

.nav-home {
  margin-top: 0px;
}

.nav-home-ios {
  margin-top: 25px;
}


.lista-ios {
  margin-top: 10px;
}

.facturasList {
  margin-top: 25px;
}

/*Navbar tarifas*/
.NavbarTarifas {
  width: 100%;
  text-align: center;

}

.NavbarTarifas ul li {
  display: inline-block;
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  margin-top: 10px;
}

.NavbarTarifas ul {
  margin: 0;
  padding: 0;
}

.NavbarTarifas li {
  margin: 10px;
}

.tipo-tarifa {
  color: black;
}

/*contrato*/

.dettalles-contrato {
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  padding-top: 10px !important;
  padding-left: 10px;
  border: 1px solid #c8c7cc;
  border-radius: 5px;

}

.precio-tarifa {
  text-align: right;
  margin-right: 10px;
}

/*contrato list*/
.tarifa-nombre {
  margin-top: 5px;
  text-align: left;
  margin-left: 10px;
  margin-right: 5px;
}

.tarifa-telefono {
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
}

.mescompleto {
  margin-left: -8px;
}

.icon-right {
  text-align: right;
  color: var(--color-corporativo);
  margin-top: -20px;

}

.contenedor-icono-descuento {
  display: inline-block;
  width: 50%;
  height: 50%;
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
  vertical-align: middle;

}

.titulo-modal-descuentos {
  color: var(--color-corporativo);
}

.contenedor-icono-descuento .icon-btn {
  color: var(--color-corporativo);
}

.progress {
  height: 1.5rem !important;
}

.descuento {
  display: inline-block;
  text-align: right;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 45%;
}

.barraPro {
  margin-bottom: 15px;
  margin-right: 10px;
}

.progress .bg-info {
  color: var(--color-corporativo) !important;
  background-color: var(--color-button) !important;

}

.descuento-detalle-div {
  background-color: white;
}

.descontados {
  margin-bottom: 0.5rem;
}

.tarifa {
  color: var(--color-corporativo);
}

.select-tlf-air {
  border-color: var(--color-corporativo);
  border-radius: 5px !important;
  border: 2px solid;
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
}

.sin-datos {
  margin-left: 15px;
}

.obligar_pass {
  margin: 25px;
}

/*facturas-contratos-tickets-list*/
.descarga {
  display: inline-block;
  float: right;
  padding: 8px 12px;
  background: #f7f7f7;
  box-shadow: 1px 2px 4px 1px #ddd;
  border-radius: 3px;
  padding-left: 15px;
  animation: gradient 15s ease infinite;
}

.mes_completo_input {
  margin-left: 15px;
}

.contrato-icon {
  display: inline-block;
}

.num-contrato {
  color: #698a0d;
}

.contrato-asociado-agru {
  display: inline-block;

}

.list-component {
  margin-top: 10px;
  padding-bottom: 7px;
  border-radius: 5px;

}

.list-component-asociado {
  margin-top: 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid;
  border: 1px solid #c8c7cc;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;

}


.icon {
  margin-right: 5px;
  color: var(--color-corporativo);
}


.num-factura {
  margin-top: 5px;
  text-align: left;
  margin-right: 5px;
}

.fecha-factura {
  text-align: left;
  margin-left: 25px;
}

.importe-factura {
  text-align: right;
  margin-right: 10px;
  color: var(--color-corporativo);
  font-size: large;
  margin-top: 10px;
}

.pagar {


  font-style: oblique;
}

.link-pagar {
  margin-bottom: 0;
}

.div-buscar {
  text-align: left;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-left: -10px;
}

.input-buscar-agenda {
  width: 90%;
  border: none;
  border-bottom: 1px solid grey;

}



.div-buscar-agenda {
  text-align: left;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.input-buscar {
  border-radius: 1px solid;

}

.btn-pressed {
  color: var(--color-corporativo);
  margin-left: 5px;
}

.btn-over {
  color: red;
  margin-left: 5px;
}

/* ticket list*/
.fecha-ticket-list {
  text-align: left;
  margin-bottom: 15px;
  margin-left: 10px;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
  margin-right: 10px;
}

.asunto-list {
  text-align: left;
  margin-left: 10px;
}

/*ticket detalle*/
.num-ticket {
  margin-top: 15px;
  text-align: left;
  margin-bottom: 15px;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.fecha-ticket {
  text-align: left;
  margin-bottom: 15px;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.descripcion-ticket {
  text-align: left;
  margin-bottom: 15px;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.asunto {
  text-align: right;
  margin-right: 10px;
}

/*tarifas*/
.nombre-tarifa {
  padding: 5px;
  display: block;
  font-size: 17px;
  font-weight: bolder;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bloque {
  text-align: center;
  padding-bottom: 5px;
  padding-top: 0px;
  margin-left: 15px;
  margin-right: 15px;
}

.detalles-btn {
  color: #ffffff;
  border: none;
  margin-bottom: 5px;
  border-radius: 6px;


}

.icon-bonos-sms {
  font-size: x-large;
  color: rgb(54 149 220);
  margin-right: 3px;
}

.icon-bonos-datos {
  font-size: x-large;
  color: rgb(240 118 60);
  margin-right: 3px;
}

.icon-bonos-llama {
  font-size: x-large;
  color: rgb(90 213 29);
  margin-right: 3px;
}

.div-container {
  border: 1px solid;

  border-radius: 8px;
  width: 260px;
  min-height: 210px;
  height: auto;
  display: inline-block;
}

.div-container-bonos {
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 340px;
  min-height: 85px;
  height: auto;
  display: inline-block;
  padding-top: 10px;
}

.btn-bonos-xl {
  background-color: var(--color-corporativo);
  color: white;
  border-radius: 5px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;
  max-width: 300px;
  min-height: 46px;
  vertical-align: middle;
  line-height: 2.5;
}


.titulo-btn-modal-bono {
  margin-left: 5px;
}


.telefono-bono {
  margin-bottom: 10px;
}

.lateral-izq {
  width: 75%;
  display: inline-block;
  text-align: left;
  margin-left: 2px;

}

.lateral-der {
  width: 20%;
  display: inline-block;
}

.container-tarifas {
  display: inline-block;
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.bloque-bonos {
  margin-bottom: 25px;
}


.container-bonos {
  display: inline-block;
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.descripcion {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  height: 11px;
  font-size: 15px;
  line-height: 12px;
  text-align: center;
}

.precio {
  font-size: 40px;
  margin-bottom: 15px;
  margin-left: 37px;

}

.precio-bono {
  font-size: 20px;
  margin-bottom: 15px;
  margin-left: 37px;

}

.precio-mes {
  font-size: 15px;
  color: #00767c;
  margin-left: 3px;
}

.precio-mes-bono {
  font-size: 15px;
  color: #00767c;
  margin-left: 3px;
}

.modal-descripcion {
  font-size: 15px;
  border: none;

}

.tarifa-detalles-text {
  margin-right: 3px;
}

.modal-cerrar {
  background-color: transparent;
  text-align: center !important;
  border: none;
  color: #f0f0f5 !important;
  float: left;
}

/*documentos firma*/

.num-documento {
  text-align: left;
  margin-top: 10px;
  margin-left: 15px;
}

.documento {
  font-weight: bold;
  margin-right: 5px;
}

/*contratos detalle*/
.detalles-contra {
  border-bottom: 1px solid #c8c7cc;
  margin-right: 15px;
  margin-left: 5px;

}

.descripcion-texto {
  margin-top: 15px;
}

.telefono {
  margin-top: 15px;

}

.icon-white-bono {
  color: white;
  margin-right: 5px;
}

.modal-cerrar-tarifa .icon-black-tarifas {
  color: white;
}

.form-group-crear-cliente .form-input {
  border: 1px solid;
}

/*Tab*/
.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.tab-content {
  margin-left: 0px !important;
  margin-right: 0px !important;

}

.Bandera-es {
  width: 100%;
  height: auto;
}

.Bandera-es-ios {
  width: 300%;
  height: 300%;
}

.idioma {
  margin-left: 10px;
  margin-top: 10px;
  width: 20%;
}

.boton-menu {
  background-color: transparent !important;
  color: #000000 !important;
  border-color: transparent !important;
}

.banderas {
  display: block;
  background-color: transparent !important;
  border: none;
}

.bandera-div {
  display: block;
}

button.banderas {
  float: left;
  width: 40px;
  height: auto;
}

.iconos {
  color: var(--color-corporativo);
  margin-right: 0px;
}

ul.list-unstyled li {
  background: #fff;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  padding-top: 10px;
  margin-top: 10px;
  border: 1px solid lightgray !important;
  padding-bottom: 10px;
}

ul.list-unstyled-agenda li {
  border: 1px solid #dfdede;
  background: #fff;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  padding-left: 0;
  list-style: none;
  margin-bottom: 15px;
}

/*Error alert*/


/*alertas*/
.error {
  --width: 90%;
  --max-width: 90%;
}

.error .alert-head.sc-ion-alert-md {
  text-align: center;
}

.error .alert-button.sc-ion-alert-md {
  color: white;
  background-color: #343a40;
  width: 22%;
  border-radius: 5px;
  justify-content: center !important;
  text-transform: none;
}

.error .alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;

}

.error .alert-message.sc-ion-alert-md {
  width: 100%;
  padding: 5px;
  margin: 2px auto;
  max-width: 60px;
}

.error img {
  height: 50px;
  width: 50px;
}

.correcto {
  --width: 90%;
  --max-width: 90%;

}

.correcto .alert-head.sc-ion-alert-md {
  text-align: center;
}

.correcto .alert-button.sc-ion-alert-md {
  color: white;
  background-color: var(--color-corporativo);
  width: 22%;
  border-radius: 5px;
  justify-content: center !important;
  text-transform: none;
}

.correcto .alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;

}

.correcto .alert-message.sc-ion-alert-md {
  width: 100%;
  padding: 5px;
  margin: 2px auto;
  max-width: 60px;
}

.correcto img {
  height: 50px;
  width: 50px;
}

.info {
  --width: 90%;
  --max-width: 90%;

}

.info .alert-head.sc-ion-alert-md {
  text-align: center;
}

.info .alert-button.sc-ion-alert-md {
  color: white;
  background-color: #343a40;
  width: 25%;
  border-radius: 5px;
  justify-content: center !important;
  text-transform: none;
}

.info .alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;

}

.info .alert-message.sc-ion-alert-md {
  width: 100%;
  padding: 5px;
  margin: 2px auto;
  max-width: 60px;
}

.info img {
  height: 50px;
  width: 50px;
}

/* pago stripe */

.btn-pagar {
  margin-top: 20px;
  background: #82878b;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.error-tarjeta {
  margin-top: 15px;
  text-align: center;

}

.btnfactura a {
  color: #100808;
}

.btnfactura {
  display: inline-block;
  float: left;
  padding: 8px 12px;
  background: #f7f7f7;
  box-shadow: 1px 2px 4px 1px #ddd;
  border-radius: 3px;
  animation: gradient 15s ease infinite;
  margin-right: 10px;
  margin-top: 18px;
  background: #ffa707;
  margin-left: 5px;
}

.btnfactura .icon {
  color: #444040;
}

.titulo-app {
  text-align: left;
  color: var(--color-corporativo);
}

.menu-lateral {
  font-size: 16px;
  display: inline-block;
  margin-left: 15px;

}

.menu-lateral-texto {
  display: inline-block;
  margin-bottom: -10px;
}

.link-menu-lateral {
  text-decoration: none;
  color: black;
  width: 100%;
  margin-bottom: -5px;
}

.iconos-menu {
  font-size: 24px;
  color: var(--color-corporativo);
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: -7px;
}

.ion-bar {
  --max-width: 70%;
  z-index: 1030;
}


/*Recargas list*/


.recarga-list-titulo {
  border-bottom: 1px solid #c8c7cc;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 5px;
  padding-bottom: 5px;
  color: var(--color-corporativo);
  font-weight: bold;
  text-align: left;
}

.recargas-id {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.recargas-fecha {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.btn-recargar {
  display: inline-block;
  float: right;
  padding: 6px 9px;
  background: #f7f7f7;
  box-shadow: 1px 2px 4px 1px #ddd;
  border-radius: 3px;
  animation: gradient 15s ease infinite;
  margin-right: 10px;
  margin-top: -51px;
  background: #ffa707;
}

.btn-recargar .icon {
  color: #555151;
}


.fechas-texto {
  margin-right: 10px;
  text-align: left;
}

.fechainicio {
  margin-bottom: 15px;
  text-align: left;
  margin-left: -8px;

}

.fechas-texto-fin {
  margin-right: 22px;
}

.importe-recarga {
  text-align: right;
  margin-top: 50px;
  margin-right: 10px;
  color: var(--color-corporativo);
  margin-bottom: 5px;
}

.btn-recargar-pagar {
  display: inline-block;
  float: left;
  padding: 6px 9px;
  background: #f7f7f7;
  box-shadow: 1px 2px 4px 1px #ddd;
  border-radius: 3px;
  animation: gradient 15s ease infinite;
  margin-left: 10px;
  margin-top: -43px;
  background: #ffa707;
  color: #343a40;
  border: none;

}

.btn-recargar-pagar .icon {
  color: #555151;
}

.modal-header-recarga {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);

}

.acerca {
  text-align: justify;
  text-indent: 20px;
}

.tarifas-descripcion-ampliada {
  text-align: justify;


}

.modal-header {
  display: inline !important;
}

.modal-cerrar-recarga {
  float: right;
  background-color: transparent;
  text-align: right;
  border: none;
  color: #f0f0f5 !important;

}

.modal-recarga .modal-header {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);


}

.modal-recarga .modal {
  width: 98% !important;
  height: 98% !important;
  margin: 5px;
}

.icon-grey {
  color: rgb(100, 98, 98);
  margin-left: 10px;
  margin-right: 5px;

}

.info-recarga {
  color: red;
  font-size: small;
}

/*Home consumo*/


.centro-grafico-quedan {
  position: relative;
  top: 67px;
  left: 150px;
}

.centro-grafico-gastado {
  position: relative;
  top: 124px;
  left: 46px;
  font-size: xxx-large;
  font-weight: 600;
  color: #698a0d;

}

.centro-grafico-gb {
  position: relative;
  top: 124px;
  left: 48px;
}

.centro-grafico-disponible {
  position: relative;
  top: 161px;
  right: 15%;
}

.icono-linea-sms {
  display: inline-block;
  margin: auto;
  width: 30%;
  text-align: center;
  border: none;
  background-color: white;
  padding: 12px;
  outline: none;

}

.icono-linea-llama {
  display: inline-block;
  margin: auto;
  width: 30%;
  text-align: center;
  border: none;
  background-color: white;
  padding: 12px;
  outline: none;

}

.icono-linea-gb {
  display: inline-block;
  margin: auto;
  width: 30%;
  text-align: center;
  border: none;
  background-color: white;
  padding: 12px;
  outline: none;

}

.contenedor-iconos {
  margin-top: 40px;
  text-align: center;
}

.iconos-consumo-home {
  font-size: 50px;
  padding: 7px;
  color: grey;

}

.modal-cerrar-tarifa {
  float: right;
  background-color: transparent;
  text-align: right;
  border: none;
  color: #f0f0f5 !important;

}

.info-consumo-home {
  margin-left: 17px;
  font-size: small;
  margin-top: 5px;
}

.info-consumo-home span {
  color: red;
  margin-left: 5px
}

.consumo-home-global {
  margin-top: 20px;
}

.iconos-consumo-home-dobles {
  font-size: 30px;
  padding: 7px;

}

.tarifa-consumo-home {
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
}

.iconos-gris {
  color: grey;
}

.consumo-home-telf {
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
  margin-bottom: 30px;
}


.select-telefonos {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: inline;

}

.select-tlf-home {
  width: 90%;
  margin-top: 10px;
  min-height: 35px;
  border-radius: 5px;
}

.select-tlf-home-div {
  margin: auto;
  text-align: center;

}

.select-tlf-home-text {
  margin-left: 20px;

}

.sin-ticket {}

.pagotarjeta iframe {
  height: 20px !important;
}

.ddi-bono-seleccionar {
  margin-top: 25px;
}

.ofertas-grafico {
  margin-top: 15px;
  font-weight: 600;
}